import React from 'react'
import Layout from '../layout/layout'
import { graphql, PageProps } from 'gatsby'
import { FixedObject } from 'gatsby-image'
import selfPublishedBooks from '../data/selfPublishedBooks'
import SelfPublishedBookView from '../components/selfPublishedBook'

type FileNode = {
  name: string
  childImageSharp: {
    fixed: FixedObject
  }
}

type DataProps = {
  allFile: {
    nodes: FileNode[]
  }
}

const SelfPublishedBooksPage: React.FC<PageProps<DataProps>> = ({
  data: {
    allFile: { nodes },
  },
}) => {
  const books = selfPublishedBooks.map(book => ({
    ...book,
    ...nodes.find(b => b.name === book.id),
  }))
  return (
    <Layout
      pageTitle="自出版电子书"
      pageSubTitle="发布在 Leanpub 上的自出版英文电子书"
    >
      {books.map((book, index) => (
        <SelfPublishedBookView
          key={index}
          book={book}
          transparent={index % 2 == 0}
        />
      ))}
    </Layout>
  )
}

export default SelfPublishedBooksPage

export const query = graphql`
  query {
    allFile(filter: { relativePath: { glob: "self-published-books/*" } }) {
      nodes {
        name
        childImageSharp {
          fixed(
            width: 300
            height: 400
            fit: COVER
            quality: 100
            background: "#fff"
            cropFocus: NORTHWEST
          ) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  }
`
