import React from 'react'
import { SelfPublishedBook } from '../models/selfPublishedBook'
import Section from './section'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from 'react-external-link'

const SelfPublishedBookView: React.FC<{
  book: SelfPublishedBook
  transparent: boolean
}> = ({ book, transparent }) => (
  <Section transparent={transparent}>
    <div className="row">
      <div className="col-md-5 center">
        <Img fixed={book.childImageSharp.fixed} />
      </div>

      <div className="col-md-7 text-center text-md-left">
        <div className="heading-block topmargin-sm">
          <h2>{book.title}</h2>
          <span>{book.subTitle}</span>
        </div>

        <p>{book.description}</p>

        <ExternalLink href={book.buyUrl}>
          <button className="button button-3d button-large button-blue">
            <i className="icon-shopping-cart" />
            购买 ${book.price}
          </button>
        </ExternalLink>
        {book.viewUrl && (
          <ExternalLink href={book.viewUrl}>
            <button className="button button-3d button-large button-teal">
              <FontAwesomeIcon icon={faGlobe} className="mr-2" />
              免费阅读
            </button>
          </ExternalLink>
        )}
      </div>
    </div>
  </Section>
)

export default SelfPublishedBookView
