import { SelfPublishedBook } from '../models/selfPublishedBook'

const selfPublishedBooks: SelfPublishedBook[] = [
  {
    id: 'from-java11-to-java17',
    title: 'From Java 11 to Java 17',
    subTitle: '从Java 11升级到Java 17',
    description: '介绍从Java 11到Java 17的改动',
    price: 9.99,
    buyUrl: 'https://leanpub.com/java11to17',
  },
  {
    id: 'junit5-cookbook',
    title: 'JUnit 5 Cookbook',
    subTitle: 'JUnit 5 介绍',
    description: '介绍JUnit 5的新特性',
    price: 7.99,
    buyUrl: 'https://leanpub.com/junit5',
  },
  {
    id: 'lodash4-cookbook',
    title: 'Lodash 4 Cookbook',
    subTitle: 'Lodash 4 介绍',
    description: 'Lodash 4介绍，更新到Lodash 版本 v4.17.4',
    price: 8.99,
    buyUrl: 'https://leanpub.com/lodashcookbook',
  },
  {
    id: 'java8-streams',
    title: 'A Practical Guide for Java 8 Lambdas and Streams',
    subTitle: 'Java 8 流和 Lambda 表达式',
    description: 'Java 8 流和 Lambda 表达式的实践开发指南',
    price: 7.99,
    buyUrl: 'https://leanpub.com/java8-lambda-expressions-streams',
  },
  {
    id: 'es6-generators',
    title: 'ES6 Generators',
    subTitle: 'ES6的生成器介绍',
    description: 'ES6 生成器的详细介绍。可以免费在线阅读。',
    price: 4.99,
    buyUrl: 'https://leanpub.com/es6generators',
    viewUrl: 'https://es6-generators.vividcode.io/',
  },
]

export default selfPublishedBooks
